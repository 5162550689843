<script setup>
const drawer = ref(true)
const authStore = useAuthStore()
const { user, logout } = authStore
const menu = useRoleBasedMenuItems()

const logoutUser = async () => {
  await logout()
  navigateTo('/')
}
</script>

<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" width="330">
      <v-row class="pa-5" align="center">
        <v-col cols="3">
          <v-avatar color="primary" size="52">
            <span class="text-h5">{{ user.username.substring(0, 2).toUpperCase() }}</span>
          </v-avatar>
        </v-col>
        <v-col cols="6">
          <p class="mt-2 font-weight-black">
            {{ user.username }}
          </p>
          <p class="text-grey">{{ user.group }}</p>
        </v-col>
        <v-col cols="3">
          <v-btn @click="logoutUser" variant="text" color="red" class="rounded-0 ml-3">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-list>
        <v-list-item
          v-for="(child, k) in menu"
          :key="k"
          :prepend-icon="child.icon"
          :value="child.text"
          exact
          style="height: 50px"
          link
          :to="child.route"
        >
          <v-list-item-title>{{ child.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img width="200" src="/von-rundstedt-logo.png"></v-img>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="9">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.v-list-item--active {
  border-left: 5px solid rgb(98, 0, 238);
  background-color: white;
}
</style>
