export const useRoleBasedMenuItems = () => {
  const { user } = useAuthStore()
  const group = user.group

  const menu = [
    {
      text: 'Home',
      route: '/admin'
    },
    {
      text: 'Partner',
      route: '/admin/partner'
    },
    {
      text: 'Mandanten',
      route: '/admin/mandanten'
    }
    /*  {
      text: 'Fragebögen',
      route: '/admin/fragebogen',
    }, */
  ]

  if (group === 'Admin') {
    return menu
  }
  if (group === 'Partner') {
    return [menu[2]]
  }
  return []
}
